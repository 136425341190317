const phrases = {
  '': '',
  locale: 'nb-NO',
  frontPage: {
    heroHeading: 'Velkommen til Statens Lokaler',
    heroParagraph:
      'Statens lokaler er en oversikt over statens eide og leide lokaler. Her får du tilgang til en søkbar database med nøkkeltall for statlig lokalleie og oversikt over statens eiendommer.',
    heroSubParagraph1:
      'Fra 1. august 2017 er oversikt over statens eiendommer tilgjengelig i en søkbar database gjennom innlogging via ID-porten.',
    heroSubParagraph2:
      'I tillegg er oversikten tilgjengelig i pdf-format, tilsvarende det trykte vedlegget til statsbudsjettet. Innen utgangen av 2018 vil løsningen også inneholde oversikt over lokaler staten leier. Virksomhetenes arbeid med innlegging av data for leide lokaler starter i januar 2018.',
    heroSubParagraph3:
      'Dersom du ønsker å søke etter eiendommer, må du logge deg inn via ID-porten øverst til høyre.',
    reportText:
      'Her kan du laste ned oversikten over eiendommene som staten eier med tilhørende grafiske fremstillinger.',
    downloadButton: 'Hent rapport',
    showGraphics: 'Vis grafikk',
    loginButton: 'til Statens lokaler',
    declinedCookies:
      'Du må godta funksjonelle cookies for å bruke Statens lokaler. Last inn siden på nytt og godta funksjonelle cookies.',
    admin: {
      option: 'Rediger framside',
      uploadPicture: 'Last opp bilde',
      text: 'Tekst',
      rapportEn: 'Rapport nr 1',
      rapportTo: 'rapport nr 2',
    },
    cookieConsent: {
      description:
        'Denne nettsiden bruker funsjonelle cookies. For å bruke nettsiden må du akseptere bruk av disse.',
      acceptButton: 'Godta',
      rejectButton: 'Avvis',
    },
  },
  searchForm: {
    label: 'Søk etter eiendom, bygninger eller lokaler',
    placeholder: 'Søk etter eiendom, grunneiendom eller bygning',
    placeholderAll: 'Alle',
    btn: 'Søk',
    fylke: 'Fylke',
    selectFylke: 'Velg fylke',
    kommune: 'Kommune',
    selectKommune: 'Velg kommune',
    organisasjon: 'Virksomhet',
    selectOrganisasjon: 'Velg virksomhet',
    departement: 'Departement',
    selectDepartement: 'Velg departement',
    rolle: 'Rolle',
    selectRole: 'Velg rolle',
    aktiv: 'Aktiv?',
    yesNo: 'Velg Ja/Nei',
    objektType: 'Objekttype',
    choose: 'Velg',
    ledigeLokalerFraDato: 'Ledig fra',
    ledigeLokalerTilDato: 'Ledig til',
    rapporteringStatus: 'Oppdateringstatus',
    rapporteringStatusPlaceholder: 'Velg status',
  },
  searchFilter: {
    apneValgFor: 'Åpne filtervalg for',
    kontorLabel: 'Er kontor',
    areal: 'Areal',
    arealAnsattTil: 'Areal per ansatt til',
    visLedigeLokaler: 'Vis ledige lokaler',
    kostnadPrKvm: 'Kostnad per kvm',
    arealPrAnsatt: 'Areal per ansatt',
    kostnadPrArsverk: 'Kostnad per årsverk',
    leieperiodeStart: 'Leieperiodestart',
    LeieperiodeSlutt: 'Leieperiodeslutt',
    til: ' til',
  },
  connectionInfo: {
    connected: 'Denne grunneiendommen er en del av',
    disconnected:
      'Denne grunneiendommen er ikke tilknyttet noen eiendomsgruppe',
    noresults: 'Ingen grunneiendommer funnet',
    fieldName: 'Legg til gruppe',
    placeholder: 'Navn på gruppe',
    ikonLabel: 'Informasjon ikon',
  },
  dashboard: {
    hello: 'Velkommen ',
    header: 'Nøkkeltall for ',
    reportHeader: 'Statens eiendommer',
    reportText:
      'Her kan du laste ned oversikten over eiendommene som staten eier med tilhørende grafiske fremstillinger.',
    reportButton: 'Last ned rapport',
    showGraphics: 'Vis grafikk',
    timestamp: 'Sist endret: ',
    lokaleListe: 'Mine lokaler',
    rapportListe: 'Rapporter',
    mobile:
      'Statens lokaler er ikke optimalisert for mobilvisning. Bruk en PC for å få full funksjonalitet.',
    headers: {
      areal: 'Kontorlokaler',
      ikkeKontorAreal: 'Ikke kontorlokaler',
      kostnader: 'Kostnader',
      ansatte: 'Ansatte',
    },
  },
  navnEdit: {
    field: {
      name: 'navnRedigering',
      label: 'Rediger Navn',
      placeholder: 'Legg inn navn',
    },
  },
  report: {
    step1Header: 'Rapportens underlag',
    step2Header: 'Kapittel 1, Statens eiendommer',
    step3Header: 'Generer rapporten',
    start: 'Start',
    back: 'Tilbake',
    next: 'Neste',
    download: 'Last ned prøve',
    publish: 'Publiser',
    intro: 'Innleiing',
    header: 'Generer vedleggsrapport',
    subHeader: 'Statistisk oversikt over statlege eigedommar',
    success: 'Rapporten er publisert!',
    visibleForOrg: 'Synlig for virksomhet',
    visibleForRole: 'Synlig for rolle',
    visibleForSubOrgs: 'Synlig for underenheter?',
  },
  powerbi: {
    header: 'Administrer PowerBI rapportvisninger',
    report: 'PowerBI rapport',
    selectReport: 'Velg PowerBI rapport',
    aggregatelevel: 'Aggregeringsnivå',
    parameter: 'Parameter',
    rolle: 'Rolle',
    reportParameters: 'Skriv inn parametere',
    reportRole: 'Skriv inn rolle',
    button: 'Rapport',
  },
  ledigeLokaler: {
    header: 'Oversikt over ledige lokaler',
    shortTitle: 'ledige lokaler',
    availableHeader: 'Registrerte ledige lokaler',
    breadcrumb: 'Ledige lokaler',
    formHeader: 'Registrering av ledig areal/lokale',
    searchPlaceholder: 'Søk etter ledige lokaler',
    caption: 'Oversikt over bygningene du har tilgang til',
    table: 'Tabell',
    map: 'Kart',
    navn: 'Navn',
    showLedigeLokaler: 'Vis mer informasjon om ledige lokaler',
    leieforholdnavn: 'Leieforholdnavn',
    leieobjektnavn: 'Leieobjektnavn',
    'grunneiendom.adresse.adressenavn': 'Gateadresse',
    'grunneiendom.kommune.navn': 'Kommune',
    'bruker.navn': 'Bruker',
    ledigeLokalerAreal: 'Areal',
    ledigeLokalerFraDato: 'Ledig fra dato',
    ledigeLokalerTilDato: 'Ledig til dato',
    ledigeLokalerLedig: 'Ledig',
    ledigeLokalerType: 'Type',
    ledig: 'Ledig',
    opptatt: 'Opptatt',
    leieperiode: 'Leieperiode',
    kontaktperson: 'Kontaktperson',
    kontaktEpost: 'E-post',
    kontaktMobil: 'Mobil',
    form: {
      header: 'Registrering av ledig areal/lokale',
      leieforhold: 'Leieforhold',
      inputLeieforhold: 'Velg leieforhold',
      leieobjekt: 'Leieobjekt',
      inputLeieobjekt: 'Velg leieobjekt',
      ledigAreal: 'Ledig Areal (BTA)',
      parkeringsAreal: 'Parkeringsareal',
      restTotaltAreal: 'Rest areal til fordeling',
      maxAreal: 'Maks areal til fordeling',
      type: 'Type ledig lokale',
      fraDato: 'Leieperiode Start',
      fraDatoPlaceholder: 'Velg startdato',
      tilDato: 'Leieperiode Slutt',
      tilDatoPlaceholder: 'Velg sluttdato',
      kommentar: 'Kommentar',
      kommentarPlaceholder: 'Skriv inn kommentar',
      kontaktperson: 'Kontaktperson Navn',
      kontaktpersonPlaceholder: 'Skriv inn navn',
      kontaktEpost: 'Kontaktperson E-post',
      kontaktEpostPlaceholder: 'Skriv inn e-post',
      kontaktMobil: 'Kontaktperson Telefon',
      kontaktMobilPlaceholder: 'Skriv inn tel.nummer',
      invalidMaxAreal: 'Areal kan ikke være større enn totalareal',
      invalidMaxparkeringsareal:
        'Areal kan ikke være større enn total parkeringsareal',
      invalidSumAreal: 'Maks areal oversteget',
      invalidWholeNumber: 'Må være gyldig heltall',
      invalidDate: 'Ikke gyldig dato format',
      invalidStartDate: 'Startdato må være før sluttdato!',
      invalidEndDate: 'Sluttdato må være før startdato!',
      cancel: 'Avbryt',
      save: 'Lagre objekt',
    },
    infoBox: {
      bruttoAreal: 'Bruttoareal',
      bruttoArealAnnet: 'Bruttoareal Annet',
      bruttoArealKontor: 'Bruttoareal Kontor',
      parkeringsAreal: 'Parkeringsareal',
      framleieAreal: 'Framleieareal',
      totalAreal: 'Totalt areal',
    },
  },
  users: {
    header: 'Administer brukere',
    edit: 'Rediger bruker',
    save: 'Lagre',
    saveAndActivate: 'Lagre og aktiver',
    email: 'E-post',
    phone: 'Telefon',
    organization: 'Hvor jobber brukeren?',
    role: 'Rolle',
    noUser: 'Brukeren finnes ikke',
  },
  help: {
    help: 'Hjelp',
    header: 'Hjelpeindex',
    newHeadline: 'Ny overskrift',
    newText: 'Skriv ny hjelpetekst her',
    newVimeo: 'Skriv inn url til vimeo-video',
    reallyCancel:
      'Vil du slutte å redigere hjelp? Dine endringer vil ikke bli lagret!',
    reallyExit: 'Du har ulagrede endringer, vil du lagre dem?',
  },
  newContract: {
    nyttlokale: 'Registrer lokale',
    nyttleieforhold: 'Registrer leieforhold',
    title: 'Registrer nytt leieforhold',
    user: 'Hvem er bruker',
    name: 'Navn på lokalet',
    aarsverk: 'Årsverk i lokalet',
    ansatte: 'Ansatte i lokalet',
    innleide: 'Innleide i lokalet',
    askName: 'Hva heter lokalet',
    lokalId: 'Lokal ID',
    kontor: 'Kontor',
    orgnr: 'Org nr.',
    numberManyears: 'Hvor mange årsverk er det i lokalet?',
    numberEmployees: 'Hvor mange ansatte er det i lokalet?',
    numberConsultants: 'Hvor mange innleide er det i lokalet?',
    localCost:
      'Hvor store er andre årlige kostnader (konto 632-639) for lokalet?',
    lagre: 'Lagre',
    tilbake: 'Tilbake',
    delete: 'X Slett leieforhold',
    confirmDelete: 'Vil du virkelig slette leieforholdet?',
    step1: {
      title: 'Kjernedata og bruker',
      name: 'Leieforholdets navn',
      internalNumber: 'Internt saksnummer',
      typeContract: 'Leietype',
      contractStart: 'Leieperiode start',
      contractEnd: 'Leieperiode slutt',
      choose: 'Velg',
      search: 'Søk',
      self: 'SELVFORVALTENDE',
      tenant: 'LEIER',
      secondaryButton: 'Rediger kjernedata',
      isOffice: 'Er dette et kontorlokale?',
    },
    step2: 'Ansatte',
    step3: {
      title: 'Utleier og leietaker',
      selvforvaltende: 'Selvforvaltende',
      opsjonNavn: 'Navn på opsjon',
      opsjonFrist: 'Frist for iverksettelse av opsjon',
      opsjonLabel: 'Har leieforholdet opsjoner?',
    },
    step4: {
      title: 'Kjernedata og løpetid',
      leietaker: 'Hvem er leietaker',
      utleier: 'Hvem er utleier',
    },
    step5: 'Eiendommer',
    step6: {
      fra: 'Gjeldende fra',
      kostnader: 'Kostnader',
      endreKostnader: 'Rediger kostnader',
      seKostnader: 'Se kostnader',
      navn: 'Navn på leieobjekt',
      kontor: 'Kontor?',
      title: 'Kostnader og areal',
      kostnaderFra: 'Kostnader gjeldende fra:',
      kostnaderFor: 'Kostnader for perioden: ',
      ukjentDato: 'Ukjent dato',
      leieObjectFra: 'Gjeldende fra: ',
      husleie: 'Husleie (konto 630-631)',
      framleiereduksjon: 'Fremleieinntekter',
      bta: 'BTA',
      parkering: 'Parkeringsareal',
      framleie: 'Fremleieinntekter',
      framleieareal: 'Fremleid areal',
      totalareal: 'Totalt areal',
      arbeidsrelatertareal: 'Herav arbeidsplassrelatert areal',
      spesialformaalareal: 'Herav areal til spesialformål',
      avbryt: 'Avbryt',
      lagreObjekt: 'Lagre objekt',
      leggTilObjekt: '+Legg til et leieobjekt',
      lagretObjekt: 'Lagret objekt med navn: ',
      fjernObjektet: 'Fjern dette objektet?',
      fullfor: 'Fullfør',
      slett: 'X Slett leieobjekt',
      slettConfirm: 'Vil du virkelig slette leieobjektet?',
    },
  },
  logo: {
    altText: 'Statsbygg logo',
  },
  navbar: {
    home: 'Hjem',
    '/d/eiendom': 'Eiendomsgrupper',
    '/d/grunneiendom': 'Grunneiendommer',
    '/d/bygning': 'Bygninger',
    eidelokalermeny: 'Eiendommer',
    '/d/lokale': 'Lokaler',
    '/d/lediglokale': 'Ledige lokaler',
    leidelokalermeny: 'Lokaler',
    rapportermeny: 'Rapporter',
    sok: 'Søk',
    project: 'Prosjekter',
    rapporter: 'Rapporter',
    minProfil: 'Min profil',
    loggUt: 'Logg ut',
    hjelp: 'Hjelp',
    innstillinger: 'Innstillinger',
    meldinger: 'Meldinger',
    support: 'Rapporter feil',
    profile: 'Profile avatar',
    openMenu: 'Åpne navigasjonsmeny',
  },
  footer: {
    companyName: 'Statens Lokaler ©2019',
    address: '',
    postal: '',
    postalAbbr: '',
    postalAddress: '',
    phone: '',
    phoneNumber: '',
    email: 'statenslokaler@statsbygg.no',
    cookies: 'Personvernerklæring',
    availability: 'Tilgjengelighetserklæring',
    about: 'Om nettstedet',
    textSize: 'A A A Juster tekststørrelse',
    siteMap: 'Nettstedskart',
    cookieInfo: 'Informasjonskapsler/cookies',
    photoUsage: 'Bruk av bilder',
    deliveredBy: 'Leveres og forvaltes av',
  },
  hitcounter: {
    showing: 'Viser ',
    ofTotal: ' av totalt ',
    hits: ' treff',
    noHits: 'Ingen treff',
  },
  notFound: {
    primaryMessage: 'Beklager, vi kunne ikke finne siden (404)',
    secondaryMessage:
      'Siden du leter etter eksisterer ikke eller har endret navn eller plassering.',
    metadata: {
      notDefined: 'ikke definert',
    },
  },
  mapData: {
    true: 'Ja',
    false: 'Nei',
    published: 'Publisert',
    draft: 'Kladd',
  },
  mapLayout: {
    kartLabel: 'Kart som beskriver plassering av bygning',
    hoppPlassering: 'Hopp til plassering',
  },
  Rolle: {
    ROLE_VIRKSOMHETSANSVARLIG: 'Virksomhetssansvarlig',
    ROLE_KONTRAKTSSANSVARLIG: 'Leieforholdsansvarlig',
    ROLE_ANALYTIKER: 'Analytiker',
    ROLE_SYSTEMFORVALTER: 'Systemforvalter',
    ROLE_PUBLIKUM: 'Publikum',
    alle: 'Alle',
  },
  eierforholdkoder: {
    H: 'Hjemmelshaver',
    F: 'Fester',
  },
  property: {
    header: '%{name}s grunneiendommer',
    searchPlaceholder: 'Søk etter grunneiendom',
    buttonTitle: 'Endre adresse',
    title: 'Grunneiendommer',
    shortTitle: 'grunneiendommer',
    detailsHeader: 'Grunneiendom',
    buildings: 'Bygninger',
    details: 'Detaljer',
    navn: 'Navn',
    bruksnavn: 'Bruksnavn',
    'adresse.adressenavn': 'Gateadresse',
    adresse: 'Adresse',
    postnr: 'Postnr / poststed',
    'kommune.navn': 'Kommune',
    kommunenr: 'Kommunenummer',
    antallBygg: 'Antall bygninger',
    etat: 'Etat',
    gardsnr: 'Gardsnummer',
    bruksnr: 'Bruksnummer',
    festenr: 'Festenummer',
    seksjonsnr: 'Seksjonsnummer',
    areal: 'Areal (BTA) kvm',
    eierforhold: 'Eier',
    'eierforhold.navn': 'Eier',
    'eierforhold.organisasjon.navn': 'Eier',
    'eierforhold.organisasjonsnummer': 'Eiers organisasjonsnr',
    'eierforhold.eierforholdKode': 'Eierforholdkode',
    eierforholdKode: 'Eierforholdkode',
    grunneiendomstype: 'Grunneiendomstype',
    'eiendom.navn': 'Eiendomsgruppe',
    'eierforhold.fraDato': 'Ervervet el.oppført',
    'eier.fraDato': 'Ervervet el.oppført',
    eiendom: 'Eiendomsgruppe',
    kulturminne: 'Kulturminne',
    geokoord: 'Geokoordinater',
    shape: 'Shape',
    matrikkelident: 'Matrikkelnavn',
    caption:
      'Tabell med oversikt over alle grunneiendommene du har tilgang til',
    untatt: 'Untatt fra rapportering',
  },
  building: {
    header: '%{name}s bygninger',
    searchPlaceholder: 'Søk etter bygning',
    caption: 'Oversikt over bygningene du har tilgang til',
    title: 'Bygninger',
    shortTitle: 'bygninger',
    detailsHeader: 'Bygning',
    property: 'Grunneiendom',
    details: 'Detaljer',
    ownDetails: 'Egne data',
    navn: 'Navn',
    'grunneiendom.adresse.adressenavn': 'Gateadresse',
    adresse: 'Adresse',
    postnr: 'Postnr / Poststed',
    bygningtype: 'Bygningtype',
    bygningsnr: 'Bygningsnummer',
    bygningstatus: 'Bygningstatus',
    fotavtrykk: 'Areal (fotavtrykk) kvm',
    etasjer: 'Etasjer',
    bruksareal: 'Areal (BRA) kvm',
    bruttoareal: 'Areal (BTA) kvm',
    egenBra: 'Bruksareal',
    egenBta: 'Bruttoareal',
    fotavtrykkAreal: 'Areal fotavtrykk',
    tattIBruk: 'Tatt i bruk dato',
    eiendomsgruppe: 'Eiendomsgruppe',
    energikarakter: 'Energikarakter',
    oppvarmingskarakter: 'Oppvarmingskarakter',
    geokoord: 'Geokoordinater',
    kulturminne: 'Kulturminne',
    'grunneiendom.kommune.navn': 'Kommune',
    'grunneiendom.kommunenr': 'Kommunenummer',
    'grunneiendom.navn': 'Grunneiendom',
    'grunneiendom.eiendom.navn': 'Eiendomsgruppe',
    'grunneiendom.eierforhold.navn': 'Eier',
    'grunneiendom.eierforhold.organisasjon.navn': 'Eier',
    'grunneiendom.eierforhold.nr': 'Eiers organisasjonsnr',
    'grunneiendom.eierforhold.eierforholdKode': 'EierforholdKode',
    'grunneiendom.eiendomSort': 'Eiendomsgruppe',
    identifikator: 'Identifikator',
  },
  floors: {
    etasjenr: 'Etasjenr',
    antallBoenheter: 'Antall boenheter',
    bruksareal: 'Bruksareal (Matrikkel)',
    egenBra: 'Bruksareal',
    bruttoareal: 'Bruttoareal (Matrikkel)',
    egenBta: 'Bruttoareal',
    etasjeplan: 'Etasjeplan',
  },
  etasje: {
    header: 'Endrede etasjer',
    searchPlaceholder: 'Søk etter etasjer',
    'bygg.grunneiendom.eierforhold.organisasjon.navn': 'Virksomhet',
    'bygg.navn': 'Bygningsnavn',
    'bygg.bygningsnr': 'Bygningsnr.',
    etasjeplan: 'Etasjeplan',
    etasjenr: 'Etasjenr.',
    bruttoareal: 'Bruttoareal (Matrikkel)',
    bruksareal: 'Bruksareal (Matrikkel)',
    egenBta: 'Bruttoareal',
    egenBra: 'Bruksareal',
    caption: 'Oversikt over endrede etasjer',
    sistEndret: 'Sist endret',
  },
  realestate: {
    header: '%{name}s eiendomsgrupper',
    searchPlaceholder: 'Søk etter eiendomsgruppe',
    caption: 'Oversikt over eiendomsgruppene du har tilgang til',
    title: 'Eiendomsgrupper',
    shortTitle: 'eiendomsgrupper',
    linkButton: 'Mer om grunneiendommen',
    navn: 'Navn',
    antallGrunneiendommer: 'Antall grunneiendommer',
    antallBygg: 'Antall Bygninger',
    'grunneiendom.kommune.navn': 'Kommune',
    'grunneiendom.kommunenr': 'Kommunenummer',
    kommuneEiendom: 'Kommune',
    kommunenrEiendom: 'Kommunenummer',
    adresseEiendom: 'Adresse',
    'grunneiendom.adresser.adressenavn': 'Gateadresse',
    'grunneiendom.eierforhold.navn': 'Eier',
    visBygningFor: 'Vis bygninger for ',
  },
  organization: {
    header: 'Administrer virksomheter',
    searchPlaceholder: 'Søk etter virksomhet',
    caption: 'Oversikt over virksomhetene',
    navn: 'Virksomhets- eller dep.navn',
    organisasjonsnummer: 'Organisasjonsnummer',
    organisasjonstype: 'Virksomhetstype',
    'forretningsadresse.kommune.navn': 'Kommune',
    harGrunneiendommer: 'Har grunneiendommer',
    antallGrunneiendommer: 'Antall grunneiendommer',
    aktiv: 'Aktiv',
    statlig: 'Statlig',
  },
  user: {
    header: 'Administer brukere',
    searchPlaceholder: 'Søk etter bruker',
    caption: 'Oversikt over brukere',
    name: 'Navn',
    firstName: 'Fornavn',
    middleName: 'Mellomnavn',
    lastName: 'Etternavn',
    roles: 'Rolle',
    'organisasjon.navn': 'Departement / Virksomhet',
    email: 'E-post',
    emailPlaceholder: 'Legg inn e-postadresse',
    emailRequired: 'E-post er påkrevd',
    emailInvalid: 'Ikke en gyldig e-postadresse',
    phone: 'Telefon',
    phonePlaceholder: 'Legg inn telefonnummer',
    phoneRequired: 'Telefonnummer er påkrevd',
    sistInnlogget: 'Sist innlogget',
    antallInnlogginger: 'Antall innlogginger',
    aktiv: 'Aktiv',
    myProfile: 'Min profil',
    delete: 'Slett bruker',
    confirmDelete:
      'Er du sikker på at du vil slette brukereren? Handlingen kan ikke angres!',
    token: 'Mitt token',
  },
  matrikkelAlert: {
    header: 'Varslinger fra Matrikkelen',
    searchPlaceholder: 'Søk etter varslinger',
    caption: 'Oversikt over varslinger',
    'organisasjon.navn': 'Departement / Virksomhet',
    endringsdato: 'Endringsdato',
    lagringsdato: 'Lagringsdato',
    matrikkelnavn: 'Matrikkelnavn',
    beskrivelse: 'Beskrivelse',
    varslingtype: 'Type',
    lest: 'Lest',
  },
  leideLokaler: {
    header: '%{name}s lokaler',
    title: 'Lokaler',
    shortTitle: 'lokaler',
    caption: 'Oversikt over leide lokaler',
    searchPlaceholder: 'Søk etter lokale',
    lediglokale: 'Ledig lokale - %{ledigeLokalerAreal} kvm',
    noLediglokale: 'Ingen ledige lokaler',
    notRegisteredLedigLokale: 'Ikke registrert ledig lokale',
    registerLedigLokale: 'Registrer som ledig',
    ID: 'ID',
    periodic: {
      title: 'Periodisk rapportering',
      title2: 'Start periodisk rapportering',
      utgaatt: 'Utgåtte lokaler skal ikke rapporteres',
      period: 'Periode',
      area: 'Areal',
      employees: 'Ansatte',
      costs: 'Kostnader',
      finish: 'Fullfør',
      start: 'Start',
      saveAndCont: 'Lagre og fortsett',
      back: 'Tilbake',
      next: 'Neste lokale',
      noObjects: 'Ingen leieobjekter funnet',
      noContracts: 'Ingen aktive leieforhold funnet',
      newPeriod: 'Status per',
      periodRange: 'For perioden',
      deadline: 'Frist rapportering',
      lokaleName: 'Lokalets navn',
      contract: 'Leieforhold',
      rentCosts: 'Husleiekostnader',
      rentSavings: 'Fremleieinntekter',
      framleieareal: 'Fremleid areal',
      requiredText: 'Felt markert med oransje må fylles ut',
    },
    navn: 'Navn',
    'bruker.navn': 'Bruker',
    eiendommen: 'Eiendommen',
    total: 'Total',
    fraDato: 'Leieperiode start',
    tilDato: 'Leieperiode slutt',
    ansatte: 'Totalt ansatte',
    kontoransatte: 'Totalt ansatte',
    ikkekontoransatte: 'Totalt ansatte',
    typeAreal: 'Type areal',
    totalareal: 'Totalt areal',
    totalKontorareal: 'Totalt kontorareal',
    totalIkkeKontorareal: 'Totalt ikke kontorareal',
    arbeidsrelatertareal: 'Herav arbeidsrelatert areal',
    arbeidsrelatertarealPerAnsatt: 'Arbeidsrelatert areal pr ansatt',
    spesialformaalareal: 'Herav areal til spesialformål',
    arealPerAnsatt: 'Areal pr. ansatt',
    sumBruttoareal: 'Bruttoareal',
    sumBruttoarealKontor: 'Bruttoareal kontor',
    sumBruttoarealAnnet: 'Bruttoareal annet',
    sumParkeringsareal: 'Parkeringsareal',
    sumFramleieareal: 'Framleieareal',
    typeKostnad: 'Type kostnad',
    husleiekostnad: 'Husleie',
    framleiereduksjon: 'Framleiereduksjon',
    totalkostnad: 'Total kostnad per år',
    kostnaderPerAar: 'Total kostnad per år',
    kostnaderPerKvm: 'Kostnad pr. kvm',
    aarsverk: 'Totale årsverk',
    kostnaderPerAarsverk: 'Kostnad pr. årsverk',
    lokalkostnader: 'Andre lokalkostnader',
    endreLokalkostnader: 'Endre lokalkostnader',
    rapporteringStatusNy: 'Oppdatert',
    missingContract: 'Mangler leieforhold',
    addContract: 'Mangler leieforhold',
    choosePeriodic: 'Gå til periodisk rapportering',
    kontor: 'Kontor',
    ikkeKontor: 'Ikke kontor',
    harLedigeLokaler: 'Ledige lokaler',
    'grunneiendom.adresse.adressenavn': 'Gateadresse',
    'grunneiendom.kommune.navn': 'Kommune',
    headers: {
      areal: 'Areal',
      ikkeKontorAreal: 'Areal',
      kostnader: 'Kostnader',
      ansatte: 'Ansatte',
    },
    numberEmp: 'Antall ansatte',
    numberYears: 'Antall årsverk',
    numberConsultants: 'Antall innleide',
    addLokale: '+ Legg til nytt lokale',
    addProject: '+ Legg til nytt prosjekt',
    addLeieforhold: '+ Legg til leieforhold',
    addLeieobject: '+ Legg til leieobjekt',
    addProperty: '+ Legg til en grunneiendom',
    editProperty: 'Endre',
    leieforholdOgObjekter: 'Leieforhold og leieobjekter',
    addUtleierLabel: 'Søk opp ny utleier',
  },
  rapporter: {
    header: 'Rapporter',
    shortTitle: 'rapporter',
    searchPlaceholder: 'Søk etter rapporter',
    caption: 'Oversikt over rapporter',
    publiseringsdato: 'Publisert',
    navn: 'Navn',
    synlig: 'Synlig for',
    rolle: 'Synlig for rolle',
  },
  prosjekt: {
    header: 'Liste over prosjekter',
    searchPlaceholder: 'Søk i prosjekt',
    navn: 'Navn',
    id: 'Nummer',
    aktiv: 'Aktivt',
    addSelection: 'Legg til utvalg',
    searchSelection: 'Søk etter utvalg',
    caption: 'Liste over prosjekt',
    prosjektnummer: 'Prosjektnummer',
    confirmDeleteProject: 'Er du sikker på at du vil fjerne prosjektet?',
    confirmDeleteSelection:
      'Er du sikker på at du vil fjerne utvalget fra prosjektet?',
  },
  adminRapporter: {
    header: 'Publiser rapporter',
    searchPlaceholder: 'Søk etter rapporter',
    caption: 'Oversikt over rapporter',
    publiseringsdato: 'Publisert',
    navn: 'Navn',
    publisert: 'Status',
    synlig: 'Synlig for',
    rolle: 'Synlig for rolle',
  },
  melding: {
    header: 'Meldinger',
    searchPlaceholder: 'Søk etter meldinger',
    newMessage: 'Send melding',
    emne: 'Emne',
    tekst: 'Melding',
    'organisasjon.navn': 'Virksomhet',
    synligForUnderenheter: 'Til underenheter',
    visibleForOrg: 'Til virksomhet',
    visibleForSubOrgs: 'Til underenheter',
    visibleForRole: 'Til roller',
    mottakere: 'Mottakere',
    roles: 'Til roller',
    organisasjoner: 'Virksomheter',
    adresser: 'Meldingen er sendt til',
  },
  error: {
    noProperty: 'Ingen grunneiendom funnet med denne IDen',
    noBuilding: 'Ingen bygning funnet med denne IDen',
    noLokale: 'Ingen lokale funnet med denne IDen',
    critical: 'Oops, det skjedde en feil',
    utgaattLokale: 'Lokalet er utgått, og skal ikke rapporteres på.',
  },
  warning: {
    propertyNoBuildings: 'Dette er en grunneiendom uten bygninger',
    unsavedName: 'Navnet er endret. Vil du lagre endringene?',
    organizationActivate:
      'Er du sikker på at du vil aktivere virksomheten? Dette vil også aktivere alle undervirksomheter',
    organizationDeactivate:
      'Er du sikker på at du vil deaktivere virksomheten? Dette vil også deaktivere alle undervirksomheter',
    noAccess: 'Du har ikke tilgang til den siden',
  },
  alert: {
    updateSuccess: 'Oppdatering utført!',
    redirect: 'Venter på å bli omdirigert',
  },
  feedback: {
    number: 'Må være et nummer',
    biggerThan: 'Fra må være mindre enn til',
    lessThan: 'Sum går ikke opp',
    invalidNumber: 'Ikke et gyldig nummer',
    invalidUrl: 'Ikke en gyldig url',
  },
  buttons: {
    cancel: 'Avbryt',
    close: 'Lukk',
    save: 'Lagre',
    fullscreen: 'Fullskjerm',
    export: 'Eksporter til excel',
  },
  globalSearch: {
    heading: 'Globalt søk',
    ukjent: 'Ukjent adresse',
    bruker: 'Eier/bruker',
    gnr: 'Gnr / Bnr',
    kontor: 'Kontor',
    areal: 'Areal',
    detaljer: 'Gå til detaljer >',
  },
  selection: {
    header: 'Utvalg',
    saveHeader: 'Lagre nytt utvalg',
    updateHeader: 'Oppdater utvalg',
    navn: 'Navn',
    type: 'Utvalgtype',
    utvalgtype: 'Utvalgtype',
    sistOppdatert: 'Sist oppdatert',
    selectionTypes: {
      KVALITETSKONTROLL: 'Kvalitetskontroll',
      PROSJEKT_OPPDRAG: 'Prosjektoppdrag',
      ANNET: 'Annet',
    },
    saveSelection: 'Lagre utvalg',
    updateSelection: 'Oppdater utvalg',
  },
  table: {
    antallRader: 'Antall rader:',
    velgRader: 'Velg antall rader per side',
    gaTilLink: 'Dette er en link. Gå til treff med navn ',
    gaTilTomLink: 'Dette er en link. Gå til treff uten navn',
  },
  tooltips: {
    lokalefrontapge: {
      lokalnavn: 'Gi lokalet et navn for å skille det fra andre lokaler.',
      lokalid:
        'Lokal ID i internt leiekontraktsoppfølgings-system. Benyttes kun av virksomheter som oppdaterer data i Statens lokaler via API',
      kontorlokalet:
        'Velg om lokalet som registreres er et kontorlokale (ja) eller ikke kontorlokale (nei)\n' +
        '\n' +
        'Kontorlokaler er lokaler med kontorbruk som hovedfunksjon som har få eller ingen spesialtilpasninger av arealene.\n' +
        '\n' +
        'Ikke kontorlokaler er lokaler med en betydelig grad av spesialtilpasninger av arealene.\n' +
        '\n' +
        'Eksempler på ikke kontorlokale kan være: fengsel, campus, lagerhaller, museer, institusjoner etc.',
    },
    lokale: {
      totalKontorareal:
        'Sum kvm av bruttoareal for kontor og parkeringsareal, men redusert for eventuelt fremleid areal for lokalet',
      totalareal:
        'Sum kvm av bruttoareal og parkeringsareal, men redusert for eventuelt fremleid areal for lokalet',
      kvmPrAnsatt:
        'Kontorareal i kvm per ansatt (inkludert innleide) i lokalet',
      totalIkkeKontorareal:
        'Sum kvm av bruttoareal for ikke kontor og parkeringsareal, men redusert for eventuelt fremleid areal for lokalet',
      arbeidsrelatertarealPerAnsatt:
        'Arbeidsplassrelatert areal i kvm per ansatt (inkludert innleide) i lokalet',
      totalKostnad:
        'Sum løpekostnader (husleie og andre lokalkostnader) per år for lokalet',
      kostPrKvm:
        'Sum årlige kostnader (husleie og andre lokalkostnader) per kvm for lokalets totale bruttoareal. ',
      aarsverk: 'Antall årsverk som er knyttet til lokalet',
      kostPrAarsverk:
        'Sum årlige kostnader (husleie og andre lokalkostnader) per årsverk som er knyttet til lokalet.',
      ansatte: 'Antall ansatte knyttet til lokalet',
    },
    dashboard: {
      totalKontorareal:
        'Sum kvm av bruttoareal  kontor og parkeringsareal men redusert for eventuelt fremleid areal for alle %{currentVirksomhet}s lokaler',
      kvmPrAnsatt:
        'Gjennomsnittlig kontorareal i kvm per ansatt (inkludert innleide) for %{currentVirksomhet}s lokaler',
      totalIkkeKontorareal:
        'Sum kvm av bruttoareal for ikke kontor og parkeringsareal men redusert for eventuelt fremleid areal for alle %{currentVirksomhet}s lokaler',
      totalKostnad:
        'Sum løpekostnader (husleie og andre lokalkostnader) per år for alle lokale til %{currentVirksomhet}',
      kostPrKvm:
        'Gjennomsnitt av sum årlige kostnader (husleie og andre lokalkostnader) per kvm av bruttoareal for alle lokaler til %{currentVirksomhet}',
      aarsverk:
        'Totalt antall årsverk som er knyttet til %{currentVirksomhet} ',
      kostPrAarsverk:
        'Gjennomsnitt av sum årlige kostnader (husleie og andre lokalkostnader) per årsverk knyttet til lokalet for alle lokaler til %{currentVirksomhet}',
      arbeidsrelatertarealPerAnsatt:
        'Arbeidsplassrelatert areal i kvm per ansatt (inkludert innleide) for %{currentVirksomhet}s ikke-kontor lokaler',
      ansatte: 'Antall ansatte knyttet til lokalet',
    },
  },
  kpi: {
    header: 'Administrer KPIer',
    feedback: 'Må være et nummer',
    edit: 'Rediger',
    kpi: 'Kpi',
    maal: 'Mål',
    grense: 'Grense',
    gjeldende: 'Gjeldende',
    areal: 'Kontorlokaler',
    ikkeKontorAreal: 'Ikke kontorlokaler',
    kostnader: 'Kostnader',
    ansatte: 'Ansatte',
    toggle: 'Vis/skjul detaljer',
    metaData: {
      totalareal: 'Totalt areal',
      totalKontorareal: 'Totalt areal',
      totalIkkeKontorareal: 'Totalt areal',
      arbeidsrelatertarealPerAnsatt: 'Arbeidsplassrelatert areal pr ansatt',
      kvmPrAnsatt: 'Kontorareal pr ansatt',
      aarsverk: 'Årsverk',
      ansatte: 'Antall ansatte',
      kostPrAarsverk: 'Kostnad pr årsverk',
      totalKostnad: 'Total kostnad pr år',
      kostPrKvm: 'Pr m\u00B2',
    },
    color: {
      green: 'Oppdatert',
      orange: 'Delvis oppdatert',
      red: 'Ikke oppdatert',
      blue: 'Utgått',
      white: 'Fremtidig',
      '': '',
    },
    lastActive: 'Kpier er fra sist aktiv dato %{outdatedDate}',
    future: 'Kpier er aktive fra og med %{futureDate}',

    AREAL_PER_ANSATT: 'Areal kvm per ansatt',
    KOSTNADER_PER_KVM: 'Kostnader per kvm',
    KOSTNADER_PER_AARSVERK: 'Kostnader per årsverk',
    ARBEIDSRELATERTAREAL_PER_ANSATT: 'Arbeidsrelatert areal per ansatt',
  },
  auditlog: {
    searchPlaceholder: 'Søk etter endringer',
    header: 'Sporing',
    caption: 'Oversikt over endringer',
    username: 'Brukernavn',
    name: 'Navn',
    operasjon: 'Operasjon',
    objekttype: 'Objektype',
    objektlink: 'Objektlink',
    objektnavn: 'Navn',
    endringstidspunkt: 'Endringstidspunkt',
  },
  availability: {
    heading: 'TILGJENGELIGHETSERKLÆRING FOR STATENSLOKALER.NO',
    text1: `Informasjon om tilgjengelighet.\n`,
    email: 'epost@epost.no',
  },
  privacy: {
    heading: 'PERSONVERNERKLÆRING FOR STATENSLOKALER.NO',
    text1: `Denne personvernerklæringen gjelder ved besøk på og bruk av nettsiden statenslokaler.no. \
Kommunal- og moderniseringsdepartementet (org. no. 972 417 858) er behandlingsansvarlig \
for personopplysninger som samles inn på statenslokaler.no. Spørsmål du måtte ha om \
behandling av dine personopplysninger i statenslokaler.no vil Statsbygg besvare på vegne \
av oss. Du kan kontakte Statsbygg på `,
    personvernHeader: 'Personvern',
    email: 'personvern@statsbygg.no',
    personvern: `Personvern er retten til privatliv og retten til å bestemme over egne personopplysninger. \
Personopplysninger er opplysninger og vurderinger som kan knyttes til en enkeltperson. Vi tar ditt personvern på alvor \
og vi behandler kun opplysninger i den grad det er nødvendig og vi har et gyldig rettslig grunnlag (jf. personvernforordningen \
art. 6 nr. 1 bokstav f ). Vi etterstreber videre å sørge for at opplysningene vi behandler er korrekte, \
at de ikke kommer på avveie og at de er tilgjengelige for de av våre ansatte som har behov for tilgang.

Du kan reservere deg mot at vi registrerer personopplysninger, men det kan medføre at vi er forhindret fra å gi deg tilgang til ønsket informasjon eller tjenester.`,
    formaalHeader: 'Formål og behandling av personopplysninger',
    formaal: `Formålet med databehandlingen er at Databehandleren skal kunne styre tilgangen til \
detaljert informasjon i statenslokaler.no og sikre at innmeldte kontaktpersoner har riktig \
tilgang til sin virksomhets data. Innmeldte kontaktpersoner defineres som \
«virksomhetsansvarlig» dersom de skal ha tilgang til å lese og redigere informasjon i Statens \
lokaler. Dersom de kun skal ha lesertilgang defineres de som «analytiker». Alle øvrige \
brukere defineres som «publikum». Statenslokaler.no må aksesseres med innlogging via ID-porten \
som er en felles innloggingstjeneste for offentlige tjenester. Digitaliseringsdirektoratet er behandlingsansvarlig \
for personopplysninger som blir håndtert i felles innlogging til offentlige tjenester.
Bruk av ID-porten til innlogging minimerer datatilgang og sikrer at kun personer med norsk personnummer og e-ID får tilgang til nettløsningen.

Utover tilgangsstyring, rollestyring og sikkerhet brukes opplysningene til analyse og \
sammenstilling av bruksmønster for å videreutvikle nettsiden og utbedre brukeropplevelsen.
    `,
    utleveringHeader: 'Utlevering av personopplysninger',
    utlevering: `Personopplysningene deles ikke med utenforstående tredjeparter.Nettsiden driftes av Knowit \
Objectnet. Tjenesten Statens lokaler er skybasert og er plassert i skyen hos Amazon. For å \
måle bruk av sidene brukes et script og informasjonskapsel (cookie) fra analyseverktøyet \
Google Analytics. Mottatte opplysninger er underlagt Google sine retningslinjer for personvern.
    `,
    rettigheterHeader: 'Dine rettigheter',
    rettigheter: `Som bruker av nettsidene våre har du rett til å kreve innsyn i personopplysningene som vi \
behandler om deg og hvordan de behandles. Du kan også kreve retting, sletting og \
begrensninger i behandlingen av personopplysninger i henhold til personopplysningsloven. \
Dersom du mener at Kommunal- og moderniseringsdepartementet ikke har overholdt dine \
rettigheter i henhold til personopplysningsloven, har du rett til å klage til den aktuelle \
tilsynsmyndigheten. Dette gjøres ved å sende klage til Datatilsynet. `,
    samtykke: `Der hvor behandlingen av personopplysninger er basert på samtykke, kan du til enhver tid trekke samtykket tilbake.\
For å ta i bruk dine rettigheter må du sende en epost til `,

    artOgHensiktHeader: 'Behandlingens art og hensikt',
    artOgHensikt: `Personopplysninger samles ved innmelding av kontaktpersoner og gjennom innlogging via \
ID-porten og lagres i en skybasert plattform hos Amazon Web Services. I tillegg samles \
anonymiserte personopplysninger via innsamling av informasjonskapsler.`,
    varighetHeader: 'Varighet',
    varighet: `For «publikumsbrukere» slettes personopplysninger automatisk etter 5 dager med inaktivitet. \
For øvrige brukere slettes personopplysninger ved endringer av ansettelsesforholdet til \
innmeldt kontaktperson eller ved utmelding fra Statens lokaler av andre årsaker.`,
    kategorierHeader: `Kategorier av registrerte`,
    kategorier: `«Virksomhetsansvarlige» og «analytikere» er nåværende ansatte i en virksomhet eller \
departement i statlig sivil sektor som er meldt inn som kontaktpersoner og brukere av \
statenslokaler.no. «Publikumsbrukere» kan være enhver person med norsk personnummer \
og en e-ID.`,
    typeOpplysingerHeader: `Type personopplysninger`,
    typeOpplysinger: `Virksomhetsansvarlige/analytikere: Navn, fødselsnummer, telefonnummer, epostadresse \
(jobb), IP-adresse, atferdsopplysninger, geolokaliseringsdata.

Publikum: Navn, fødselsnummer, IP-adresse, atferdsopplysninger, geolokaliseringsdata.`,
    sikkerhetstiltak: `TEKNISKE OG ORGANISATORISKE SIKKERHETSTILTAK`,
    pseudonymiseringHeader: `Pseudonymiseringstiltak`,
    pseudonymisering: `For å måle bruk av sidene brukes et script og informasjonskapsel (cookie) fra \
analyseverktøyet Google Analytics. IP-adresse samles inn for å rapportere på \
geolokasjonsdata. IP-adressene anonymiseres slik at geolokasjonsdata ikke spores på bynivå.`,
    krypteringHeader: `Krypteringstiltak`,
    kryptering: `Alt av kommunikasjon både inn til statenslokaler.no og ut til eksterne tjenester er kryptert via \
https. Tilgang til serverne i Amazon Web Services skjer kun via krypterte kanaler. Det \
samme gjelder for serverne som kjører de forskjellige applikasjonene som ikke er tilgjengelige utenfra.`,
    fortrolighetHeader: `Tiltak for å sikre personopplysningenes fortrolighet`,
    fortrolighet: `Tilgang til personopplysninger er begrenset til systemforvaltere av statenslokaler.no. Hos \
underdatabehandleren er dette begrenset til 1 person og hos databehandler skal det til \
enhver tid aldri være flere enn 5 systemforvaltere.`,
    integritetHeader: `Tiltak for å sikre personopplysningenes integritet`,
    integritet: `Hvert år, i forkant av ny årlig rapporteringsperiode, utfører forvaltningen en kontroll av \
virksomhetsansvarlige og analytikere for å sørge for at de utnevnte kontaktpersonene per \
virksomhet fortsatt er gjeldende og at brukere som ikke lenger ønsker tilgang har muligheten \
til å melde fra om det.`,
    andreHeader: `Andre datasikkerhetstiltak`,
    andre: `Datatilgang er minimert via innlogging og ved at tilgang til data kun gjøres via API.`,
  },
  helpToolTipButton: {
    lokalTemplate: {
      periodictitle:
        'Bruke denne knappen for å starte veilederen til periodisk rapportering av ny periode for dette lokalet',
      registerLedigLokaletitle:
        'Bruk denne knappen for å registrere dette areal/lokale som helt eller delvis ledig for en periode',
    },
    lokale: {
      visKunLedige: 'Vis kun lokaler som har ledig areal',
      visKunUtgaatte: 'Vis kun utgåtte lokaler',
      visMine:
        'Vis kun lokaler som tilhører din virksomhet, inkludert lokaler som er utgått',
      visKunAktive: 'Vis kun lokaler som er aktive',
      sokLokale: 'Søk etter lokale',
      filterValg: 'Åpne filtervalg for søk',
      sokeknapp: 'Søkeknapp',
      visUtgaatte: 'Vis utgåtte lokaler',
      visLedige: 'Vis ledige lokaler',
      visAktive: 'Vis aktive lokaler',
    },
    newLokale: {
      step1: {
        lokalnavn: 'Gi lokalet et navn for å skille det fra andre lokaler.',
        lokalid:
          'Lokal ID i internt leiekontraktsoppfølgings-system. Benyttes kun av virksomheter som oppdaterer data i Statens lokaler via API',
        kontorlokalet:
          'Velg om lokalet som registreres er et kontorlokale (ja) eller ikke kontorlokale (nei)' +
          'Kontorlokaler er lokaler med kontorbruk som hovedfunksjon som har få eller ingen spesialtilpasninger av arealene.\n' +
          '\n' +
          'Ikke kontorlokaler er lokaler med en betydelig grad av spesialtilpasninger av arealene.\n' +
          '\n' +
          'Eksempler på ikke kontorlokale kan være: fengsel, campus, lagerhaller, museer, institusjoner etc.',
      },
      step2: {
        aarsverk:
          'Antall årsverk basert på summen av ansatte og innleide i lokalet på aktuelt rapporteringstidspunkt. Årsverk regnes ut ved å summere “stillingsprosenter” for aktuelle ansatte og innleide. Årsverk registreres med presisjon på en desimal, for eksempel 7,4.',
        ansatte:
          'Antall ansatte omfatter de som lønnes av deres virksomhet, dvs. inkludert vikarer og engasjerte.',
        innleide:
          'Antall innleide (i mer enn 50% stilling over mer enn 6 mnd. siste år) i dette bygg eller lokale. Innleid personell omfatter de som har ansettelse i et annet firma, og får sin lønn derfra.',
        kostnad:
          'Årlige kostnader i kroner eks. mva. hentet fra regnskapstall og grupper iht. standard kontoplan for statlige virksomheter, konto 632, 634, 636, 639, 660, 663. For selvforvaltende lokaler inkluderer dette også kapital/avskrivninger (601).',
      },
      step3: {
        selvforvaltende: 'Disponerer, forvalter og drifter lokalet selv',
        leietaker:
          'Hvis Selvforvaltende er “Ja” er det bruker selv (automatisk utfyllt), ellers er det organisasjonen som leier lokalet.',
        utleier:
          'Søk opp organisasjonsnummeret til\n' +
          'virksomheten som står som utleier i\n' +
          'leiekontrakten. Hvis selvforvaltende er «ja»\n' +
          'er dette feltet ikke mulig å gi verdi.',
      },
      step4: {
        contractName:
          'Gi leieforholdet et navn for å skille det fra andre leieforhold',
        internalNumber: 'Internt saksnummer i eget arkivsystem.',
        opsjonLabel:
          'Dersom leieforholdet inneholder muligheter for forlengelse velg «ja». Informasjon om opsjonen vil så kunne legges\n' +
          'inn.',
      },
      step6: {
        husleie:
          'Årlig husleie i kroner inkl. mva kompensasjon (der det er tilfelle). Hentet fra regnskapstall og gruppert iht. standard kontoplan for statlige virksomheter, konto 630/631.',
        framleiereduksjon:
          'Årlig inntekter av fremleie av hele eller deler av lokalet til leieforholdet. Hentet fra regnskapstall og gruppert iht. standard kontoplan for statlige virksomheter, konto 360.',
      },
    },
    periodicReport: {
      step2: {
        bta:
          'Totalt leid areal (BTA) inkludert fellesarealer oppgitt i leiekontrakten og eventuelt\n' +
          'fremleid areal. BTA er areal begrenset av ytterveggens utside eller midt i delevegg.',
        typeareal:
          'Spesialformål defineres som areal utover det som i arealnormen for statlige\n' +
          'byggeprosjekter er definert som kontorbygg. Dette kan f.eks. være kurslokaler for\n' +
          'eksterne, publikumsmottak, laboratorier, treningsrom.',
        parkeringsareal:
          'Arealer til parkering innomhus. Inkluderer ikke utendørs parkering',
        framleie: 'Fremleid areal i henhold til avtale med fremleier',
        totaltareal:
          'Totalt areal er arealer til eksklusiv bruk for leietaker/eier. Dette regnes ut ved å legge\n' +
          'til parkeringsareal og trekke fra fremleid areal.',
      },
      step3: {
        numberEmp:
          'Antall ansatte omfatter de som lønnes av deres virksomhet, dvs. også vikarer og engasjerte',
        numberYears:
          'Antall årsverk basert på ansatte og innleide i lokale på aktuelt rapporterings tidspunkt. Årsverk regnes ut ved å summere «stillingsprosenter» for aktuelle ansatte og innleide.  Årsverk registreres med presisjon på en desimal, for eksempel 7,4',
        numberConsultants:
          'Antall innleide (i mer enn 50% stilling over mer enn 6 mnd. siste år) i dette bygg eller lokaler. Innleid personell omfatter de som har ansettelse i et annet firma, og får sin lønn derfra',
      },
      step4: {
        rentCosts:
          'Årlig husleie i kroner inkl. mva kompensasjon (der det er tilfelle) hentet fra regnskapstall og gruppert iht. standard kontoplan for statlige virksomheter, konto 630/631',
        rentSavings:
          'Årlig inntekt fra framleide lokaler. Hentet fra regnskapstall og gruppert iht. standard kontoplan for statlige virksomheter, konto 360',
        lokalkostnader:
          'Årlige kostnader i kroner eks. mva. Hentet fra regnskapstall og gruppert iht. standard kontoplan for statlige virksomheter, konto 632, 634, 636, 639, 660, 663. For selvforvaltede lokaler inkluderer dette også kapitalkostnader/avskrivninger (601)',
      },
    },
    ledigelokaler: {
      bta:
        'Ledig areal til leieobjektet. Bruttoareal (BTA) er areal begrenset av ytterveggens utside \
      eller midt i delevegg og summeres for alle leieobjekter inkludert andel fellesarealer oppgitt i leiekontrakten',
      parkeringsareal: 'Ledig areal til parkering innomhus for leieobjekt',
      restareal:
        'Resterende bruttoareal av det som ikke er ledig for leieobjektet',
      maksareal:
        'Dette er den maksimale grensen du kan registrere som ledig og er det samme som bruttoarealet til angitt leieobjektet. \
      Dersom leieobjektet er registert som ledig allerede, vil dette tallet være resterende areal som ikke er registrert som ledig i leieobjektet i samme periode',
    },
    user: {
      visKunAktive: 'Vis kun brukere som er aktive',
      visAktive: 'Vis aktive brukere',
    },
    organization: {
      visKunAktive: 'Vis kun virksomheter som er aktive',
      visAktive: 'Vis aktive virksomheter',
    },
  },
  pagination: {
    velgSidetall: 'Velg sidetall',
  },
};

export default phrases;
