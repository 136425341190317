import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { localize, toGuiDate } from 'utils/utils';

import {
  ActionLink,
  Button,
  CommentBox,
  ContractPanel,
  DataItem,
  ExpandableList,
  Heading,
  HelpToolTipButton,
  KpiTarget,
  LedigeLokalerListPanelModal,
  Link,
  LoadingOverlay,
  LokaleNameModal,
  Panel,
} from 'components';
import LeafletMap from 'containers/Map/LeafletMap';
import styles from './LeideLokalerDetailTemplate.css';
import LokaleKpiPanels from '../../organisms/LokaleKpiPanels/LokaleKpiPanels';

const LeideLokalerDetailTemplate = ({
  loading,
  user,
  lokale,
  openContract,
  handleContractClick,
  openNewLeieobject,
  fullRefresh,
  toggleModal,
  modals,
  ...props
}) => {
  const {
    editable: hasEditAccess,
    canView: hasViewAccess,
    leieforholds = [],
    grunneiendom = {},
    bruker = {},
    totalareal,
    sistEndret,
    ledigeLokalerAreal,
    harLedigeLokaler,
  } = lokale;

  const aktiveLeieforhold = leieforholds.filter(it => it.aktiv);
  const showKpiAndContracts = hasViewAccess || hasEditAccess;

  const harLedigeLokalerText =
    harLedigeLokaler && ledigeLokalerAreal > 0
      ? polyglot.t('leideLokaler.lediglokale', {
          ledigeLokalerAreal,
        })
      : polyglot.t('leideLokaler.noLediglokale');

  const powerBIprops = lokale.rapportMeta
    ? {
        aggregeringsnivaa: lokale.rapportMeta.aggregeringsnivaa,
        rapportId: lokale.rapportMeta.rapportId,
        datasetId: lokale.rapportMeta.datasetId,
        embedUrl: lokale.rapportMeta.embedUrl,
        token: lokale.rapportMeta.token,
      }
    : {};

  if ((loading && !lokale.id) || fullRefresh) return <LoadingOverlay />;

  return (
    <div className={styles.wrapper}>
      {!user.isPublic &&
        sistEndret && (
          <div className={styles.lastChanged}>
            {`Sist endret: ${toGuiDate(sistEndret, true)}`}
          </div>
        )}
      <div className={styles.overview}>
        <div className={styles.leftOverview}>
          <div className={styles.nameLine}>
            <Heading level={0} noMargin noPadding>
              {lokale.navn || polyglot.t('notFound.metadata.notDefined')}
            </Heading>
            {hasEditAccess && (
              <LokaleNameModal {...{ lokale, toggleModal, modals, ...props }} />
            )}
          </div>

          {lokale.lokalId && (
            <Heading className={styles.lokalIdHeading} level={1}>
              {`${polyglot.t('leideLokaler.ID')} ${lokale.lokalId}`}
            </Heading>
          )}
          <Heading level={1} italic>
            {lokale.kontor
              ? polyglot.t('leideLokaler.kontor')
              : polyglot.t('leideLokaler.ikkeKontor')}
          </Heading>
          <div className={styles.infoLine}>
            <div className={styles.overviewItems}>
              <DataItem
                metaData={polyglot.t('leideLokaler.bruker.navn')}
                data={bruker.navn.split(' - ')[0]}
                vertical
                wrap
              >
                {`Orgnr: ${bruker.organisasjonsnummer}`}

                {bruker.naeringskode1 ? <div>{bruker.naeringskode1}</div> : ''}
                {bruker.naeringskode2 ? <div>{bruker.naeringskode2}</div> : ''}
                {bruker.naeringskode3 ? <div>{bruker.naeringskode3}</div> : ''}
              </DataItem>
            </div>
            <div className={styles.overviewItems}>
              <DataItem
                metaData={polyglot.t('leideLokaler.eiendommen')}
                wrap
                data={
                  <Link
                    target="_blank"
                    to={`/d/grunneiendom/${grunneiendom.matrikkelId}`}
                    classic
                  >
                    {grunneiendom.visningsnavn}
                  </Link>
                }
                vertical
              >
                {`${grunneiendom.adresse && grunneiendom.adresse.adressenavn}`}
              </DataItem>
            </div>
            {!user.isPublic &&
              !showKpiAndContracts && (
                <div className={styles.overviewItems}>
                  <DataItem
                    metaData={polyglot.t('leideLokaler.totalareal')}
                    wrap
                    data={`${localize(totalareal)} m\u00B2`}
                    vertical
                  />
                </div>
              )}
          </div>
          <div className={styles.buttonRow}>
            {hasEditAccess && (
              <div>
                {lokale.rapporteringStatusNyCurrent !== 'blue' ? (
                  <div className={styles.updateLine}>
                    <Button
                      secondaryButton
                      to={`/d/lokale/${lokale.id}/periodisk`}
                    >
                      {polyglot.t('leideLokaler.periodic.title')}
                    </Button>
                    <HelpToolTipButton
                      className={styles.center}
                      title={polyglot.t(
                        'helpToolTipButton.lokalTemplate.periodictitle',
                      )}
                    />
                  </div>
                ) : (
                  <div className={styles.updateLine} />
                )}
                <KpiTarget
                  alignLeft
                  status
                  color={lokale.rapporteringStatusNy}
                  className={styles.indicatorpos}
                />
                {lokale.rapporteringStatusNy === 'blue' && (
                  <span className={styles.comments}>
                    {`Sist aktivt ${lokale.tilDato}`}
                  </span>
                )}
                {lokale.rapporteringStatusNy === 'orange' && (
                  <ActionLink
                    hasContract={lokale.harLeieforhold}
                    addLeieobjekt={
                      lokale.manglerLeieobjekt && openNewLeieobject
                    }
                    lokaleId={lokale.id}
                  />
                )}
              </div>
            )}
            {hasEditAccess && (
              <div className={styles.buttonRowPadding}>
                <div className={styles.updateLine}>
                  <Button
                    secondaryButton
                    minWidth
                    to={`/d/lokale/${lokale.id}/ledigeLokaler`}
                  >
                    {polyglot.t('leideLokaler.registerLedigLokale')}
                  </Button>
                  <HelpToolTipButton
                    title={polyglot.t(
                      'helpToolTipButton.lokalTemplate.registerLedigLokaletitle',
                    )}
                  />
                </div>
                <div className={styles.ledigInfo}>
                  {harLedigeLokaler
                    ? harLedigeLokalerText
                    : polyglot.t('leideLokaler.notRegisteredLedigLokale')}
                </div>
              </div>
            )}
          </div>
          {!user.isPublic &&
            !hasEditAccess && (
              <Fragment>
                <Heading level={3}>
                  {harLedigeLokaler
                    ? harLedigeLokalerText
                    : polyglot.t('leideLokaler.notRegisteredLedigLokale')}
                </Heading>
                <LedigeLokalerListPanelModal
                  {...{
                    lokale,
                    isReadOnly: true,
                    toggle: toggleModal,
                    showModal: modals.showLedigeLokalerModal,
                  }}
                />
              </Fragment>
            )}
        </div>
        <div className={styles.rightOverview}>
          <Panel className={styles.mapPanel}>
            <LeafletMap
              property={lokale.grunneiendom}
              onBuildingPick={() => {}}
              markers={
                lokale.grunneiendom &&
                lokale.grunneiendom.bygninger &&
                lokale.grunneiendom.bygninger.filter(it => it.plassering)
              }
            />
          </Panel>
        </div>
      </div>

      <LokaleKpiPanels
        lokale={lokale}
        aktiveLeieforhold={aktiveLeieforhold}
        showKpiAndContracts={showKpiAndContracts}
        hasEditAccess={hasEditAccess}
        powerBIprops={powerBIprops}
        modals={modals}
        toggleModal={toggleModal}
        {...props}
      />

      {showKpiAndContracts && (
        <Panel className={styles.contracts}>
          <div className={styles.header}>
            <Heading green level={1}>
              {polyglot.t('leideLokaler.leieforholdOgObjekter')}
            </Heading>
            {hasEditAccess && (
              <Button
                kind="primary"
                newItemButton
                to={`/d/lokale/${lokale.id}/nyttleieforhold`}
              >
                {polyglot.t('leideLokaler.addLeieforhold')}
              </Button>
            )}
          </div>
          {props.loadingSaveContract || loading ? (
            <LoadingOverlay />
          ) : (
            <div className={styles.contractList}>
              <ExpandableList
                openPanel={openContract}
                handleClick={handleContractClick}
                type="contract"
              >
                {aktiveLeieforhold.map(leieforhold => (
                  <ContractPanel
                    {...props}
                    {...leieforhold} // TODO ikke spre inn leieforhold når ferdig å refaktorere
                    user={user}
                    modals={modals}
                    toggleModal={toggleModal}
                    hasEditAccess={hasEditAccess}
                    leieforhold={leieforhold}
                    key={leieforhold.id}
                    index={leieforhold.id}
                    lokaleId={lokale.id}
                    kontor={lokale.kontor}
                    overflow={
                      leieforhold.leieobjekter.length +
                        leieforhold.grunneiendommer.length >
                      9
                    }
                    removalNotAllowed={leieforhold.grunneiendommer.length <= 1}
                  />
                ))}
              </ExpandableList>
            </div>
          )}
        </Panel>
      )}
      {(hasViewAccess || hasEditAccess) && (
        <CommentBox
          editable={hasEditAccess}
          originalComment={lokale.kommentar}
          className={styles.section}
          modals={modals}
          toggleModal={toggleModal}
          {...props}
        />
      )}
    </div>
  );
};

LeideLokalerDetailTemplate.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    roles: PropTypes.array,
  }),
  lokale: PropTypes.shape({
    lokaleperiode: PropTypes.object,
    leieforholds: PropTypes.array,
    bruker: PropTypes.object,
    grunneiendom: PropTypes.object,
    sistEndret: PropTypes.string,
  }),
  openContract: PropTypes.number,
  handleContractClick: PropTypes.func,
  openNewLeieobject: PropTypes.func,
  toggle: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  hasViewAccess: PropTypes.bool,
  lokaleperiode: PropTypes.object,
  leieforholds: PropTypes.array,
  grunneiendom: PropTypes.object,
  bruker: PropTypes.object,
};

export default LeideLokalerDetailTemplate;
