import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Heading, Panel, DataItem } from 'components';
import styles from './ProfilePage.css';

const ProfilePage = ({ user }) => {
  const date = new Date(user.sistInnlogget);
  const isVirkshomhetsAnsvarligOrAdmin =
    user.roles.indexOf('ROLE_VIRKSOMHETSANSVARLIG') > -1 ||
    user.roles.indexOf('ROLE_SYSTEMFORVALTER') > -1;
  return (
    <div className={styles.wrapper}>
      <Heading>{polyglot.t('user.myProfile')}</Heading>
      <Panel>
        <DataItem metaData={polyglot.t('user.name')} data={user.name} />
        <DataItem
          metaData={polyglot.t('user.organisasjon.navn')}
          data={user.organisasjon.navn}
        />
        <DataItem metaData={polyglot.t('user.email')} data={user.email} />
        <DataItem metaData={polyglot.t('user.phone')} data={user.phone} />
        <DataItem
          metaData={polyglot.t('user.roles')}
          data={user.roles.map(role => polyglot.t(`Rolle.${role}`)).join(', ')}
        />
        <DataItem
          metaData={polyglot.t('user.sistInnlogget')}
          data={`${date.toLocaleDateString(
            polyglot.t('locale'),
          )} - ${date.toLocaleTimeString(polyglot.t('locale'))}`}
        />
        <DataItem
          metaData={polyglot.t('user.antallInnlogginger')}
          data={user.antallInnlogginger}
        />
      </Panel>
      {isVirkshomhetsAnsvarligOrAdmin && (
        <Panel>
          <DataItem
            metaData={polyglot.t('user.token')}
            data={localStorage.token}
          />
        </Panel>
      )}
    </div>
  );
};

ProfilePage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    organisasjon: PropTypes.shape({}).isRequired,
    roles: PropTypes.array.isRequired,
    antallInnlogginger: PropTypes.number,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default ProfilePage;
